import "./hys.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useNavigate,
  Link,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  FaFacebook,
  FaXTwitter,
  FaCircleCheck,
  FaCircleArrowLeft,
  FaRegCircle,
  FaMagnifyingGlass,
  FaRegEnvelope,
  FaPhone,
} from "react-icons/fa6";
import mp_data from "./mp_data.json";
// import {HysProvider, useHysData} from  "./hys-context";
import React, { createContext, useContext, useState } from "react";

const isMatch = (value, searchTerm) => {
  if (typeof value === "string") {
    return value.toLowerCase().includes(searchTerm.toLowerCase());
  } else if (Array.isArray(value)) {
    return value.some((item) => isMatch(item, searchTerm));
  } else if (typeof value === "object" && value !== null) {
    return Object.values(value).some((item) => isMatch(item, searchTerm));
  }
  return false;
};

const emailLink = (addresses) => {
  // if the email address contains non-ascii characters, the username for the email address will need to be encoded. the domain and TLD should be left as is.
  // e.g. "Johñ.Doe@domain" should be encoded as "Joh%F1.Doe@domain"

  let encodedEmails = [];
  for (const email of addresses) {
    const [username, domain] = email.split("@");
    encodedEmails.push(
      encodeURIComponent(username) + "@" + domain
    );
  }

  return "mailto:" + encodedEmails.join(";");
}
const emailPeople = (address) => {
  if (typeof address == "string") {
    address = [address];
  }

  window.location.href = emailLink(address);
};

const CardLink = ({ children, imageUrl, title, linkUrl }) => {
  return (
    <Link
      to={linkUrl}
      className="block bg-white hover:shadow-lg transition-shadow overflow-hidden border-8 border-white max-w-96"
    >
      {children}
      <div className=" ">
        <img src={imageUrl} alt={title} className="object-fill w-96 h-64" />
      </div>
      <div className="py-1 text-center bg-primary text-white text-lg font-semibold ">
        {title}
      </div>
    </Link>
  );
};
const PrimaryButton = ({ children, onClick, url }) => {
  return (
    <a
      onClick={onClick}
      {...(url ? { href: url, target: "_blank" } : {})}
      className="bg-primary px-8 py-4  rounded-full font-bold text-xl text-white mx-auto cursor-pointer"
    >
      {children}
    </a>
  );
};
const SecondaryButton = ({ children, onClick, url }) => {
  return (
    <a
      onClick={onClick}
      {...(url ? { href: url, target: "_blank" } : {})}
      className="bg-secondary px-8 py-4  rounded-full font-bold text-xl  text-white mx-auto cursor-pointer"
    >
      {children}
    </a>
  );
};

const HomePage = () => {
  return (
    <>
      <div className="text-center max-w-[800px] mx-auto">
        <p className="px-8 mb-10 ">
        As its name suggests, Have Your Say is all about having your say, by making it easy for you to directly contact Members of Parliament. It is a website for contacting MPs - whether it’s your local Electorate MP or a Ministerial MP responsible for a specific function of government (e.g. Minister of Justice).
        </p>
        <div className="m-8">
          <SecondaryButton
            url="https://vote.nz/maps/find-your-electorate/"
            className="m-20 mx-auto"
          >
            Find your Electorate
          </SecondaryButton>
        </div>
      </div>
      <ContentWrapper pageclassName="bg-accent">
        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-8">
          {/* Sections for other features like emailing MPs, contact details, etc. Each child div here represents one feature. */}
          <CardLink
            imageUrl="/images/Email Selected MPs.jpg"
            title="Email selected MPs"
            linkUrl="/select"
          />

          <CardLink
            imageUrl="/images/Electorates and MPs.jpg"
            title="Electorates and their MPs"
            linkUrl="/electorate"
          />
          <CardLink
            imageUrl="/images/Full Contact Details.jpg"
            title="Full contact details for MPs"
            linkUrl="/mps"
          />
          <CardLink
            imageUrl="/images/All Parties.jpg"
            title="Email all party MPs"
            linkUrl="/parties"
          />
          <CardLink
            imageUrl="/images/Email Tips.jpg"
            title="Guidelines for emailing MPs"
            linkUrl="/guidelines"
          />
          <CardLink
            imageUrl="/images/Ministerial MPs.jpg"
            title="Ministerial MPs"
            linkUrl="/ministerial"
          />
          {/* Repeat for other sections */}
        </section>
      </ContentWrapper>
    </>
  );
};

const ElectorateMPsPage = () => {
  const { data } = useHysData();
  const [selectedMPs, setSelectedMPs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleMPSelected = (slug) => {
    setSelectedMPs((prevSelectedMPs) => {
      if (prevSelectedMPs.includes(slug)) {
        return prevSelectedMPs.filter((id) => id !== slug);
      } else {
        return [...prevSelectedMPs, slug];
      }
    });
  };

  const isMPSelected = (slug) => selectedMPs.includes(slug);

  const filteredMPs = data.people.filter(
    (mp) =>
      mp.electorate &&
      mp.electorate.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <section className="max-w-[1140px] mx-auto px-4 mb-4 pt-4 pb-8 px-20">
        <h1 className="text-3xl uppercase font-bold text-center py-2 text-secondary">
          Email selected MPs
        </h1>
        <p className="text-xl font-semi text-center">
        Search electorates (geographical constituencies) and contact the associated electorate MPs. You can contact multiple MPs at the same time. 
        </p>
      </section>
      <ContentWrapper>
        <section className="pt-8 pb-2 w-full">
          <SearchBar
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            label="Electorate"
          />

          <div className="divide-y bg-white mt-8">
            {filteredMPs.map((mp) => (
              <CheckableItem
                key={mp.id}
                title={mp.name}
                subtitle={
                  mp.party + " " + (mp.electorate ? mp.electorate : "List MP")
                }
                checked={isMPSelected(mp.slug)}
                onChange={() => toggleMPSelected(mp.slug)}
              />
            ))}
          </div>
          <div className="mt-8 mb-4 flex flex-row-reverse">
            <div>
              <PrimaryButton
                onClick={() => {
                  const selectedEmails = data.people
                    .filter((mp) => selectedMPs.includes(mp.slug))
                    .map((mp) => mp.email);
                  emailPeople(selectedEmails);
                }}
              >
                Email MPs
              </PrimaryButton>
            </div>
          </div>
        </section>
      </ContentWrapper>
    </>
  );
};
const MPListingPage = () => {
  const { data } = useHysData(); // Assuming data is an array of MPs
  const [searchTerm, setSearchTerm] = useState("");

  const filteredMPs = data.people.filter((mp) => isMatch(mp, searchTerm));

  return (
    <>
      <section className="max-w-[1140px] mx-auto px-4 mb-4 pt-4 pb-8 px-20">
        <h1 className="text-3xl uppercase font-bold text-center py-2 text-secondary">
          MPs Contact Info
        </h1>
        <p className="text-xl font-semi text-center">
        Get the full contact details for MPs in the current New Zealand Parliament. 

        </p>
      </section>
      <ContentWrapper>
        <section className="pt-8 pb-2 w-full">
          <SearchBar
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            label="Name"
          />

          <div className="divide-y bg-white mt-8">
            {filteredMPs.map((mp) => (
              <Link
                key={mp.id}
                to={`/mp/${mp.slug}`}
                className="flex flex-row py-3 px-4 hover:bg-neutral"
              >
                <div className="text-xl font-semibold pl-2 text-secondary">
                  {mp.name}
                </div>
                <div className="text-lg pl-2">
                  {mp.party + " " + (mp.electorate ? mp.electorate : "List MP")}
                </div>
              </Link>
            ))}
          </div>
          <div className="mt-8 mb-4 flex flex-row-reverse">
            <div></div>
          </div>
        </section>
      </ContentWrapper>
    </>
  );
};
const MinisterialMPsPage = () => {
  const { data } = useHysData();
  const [searchTerm, setSearchTerm] = useState("");

  const filteredPortfolios = data.portfolios.filter((portfolio) =>
    portfolio.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <section className="max-w-[1140px] mx-auto px-4 mb-4 pt-4 pb-8 px-20">
        <h1 className="text-3xl uppercase font-bold text-center py-2 text-secondary">
          Ministerial MPs
        </h1>
        <p className="text-xl font-semi text-center">
        Ministers of the Crown are Members of Parliament who hold ministerial warrants to perform certain functions of government. (e.g. Minister of Justice, Minister of Transport, Minister of Police etc.). The warrant sets out the minister's responsibilities, powers, and duties within their portfolio. Ministers collectively make up the executive branch of the New Zealand state. Most ministers are members of Cabinet.
</p><p className="text-xl font-semi text-center pt-4">
Go ahead and search the different portfolios, and make contact with the ministers responsible. 
        </p>
      </section>
      <ContentWrapper>
        <section className="pt-8 pb-2 w-full">
          <SearchBar
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            label="Portfolio"
          />

          <div className="divide-y bg-white mt-8">
            {filteredPortfolios.map((portfolio) => (
              <Link
                key={portfolio.name}
                to={`/mp/${portfolio.mp.slug}`}
                className="flex flex-row py-3 px-4 hover:bg-neutral"
              >
                <div className="text-xl font-semibold pl-2 text-secondary">
                  {portfolio.name}
                </div>
                <div className="text-lg pl-2">{portfolio.mp.name}</div>
              </Link>
            ))}
          </div>
        </section>
      </ContentWrapper>
    </>
  );
};
const PartyMPsPage = () => {
  const { data } = useHysData();

  // data.parties has a list of all parties with id and name
  // data.people has a list of all MPs with party as a reference to data.parties.id
  // add a list of all MPs to each party
  const parties = data.parties.map((party) => {
    party.mps = data.people.filter((mp) => mp.party_id === party.id);
    return party;
  });
 
  return (
    <>
      <section className="max-w-[1140px] mx-auto px-4 mb-4 pt-4 pb-8 px-20">
        <h1 className="text-3xl uppercase font-bold text-center py-2 text-secondary">
        EMAIL ALL PARTY MPS
        </h1>
        <p className="text-xl font-semi text-center">
        Email all the MPs belonging to a political party.
        </p>
      </section>
      <ContentWrapper>
        <section className="pt-2 pb-2 w-full">
          <div className="divide-y bg-white">
            {parties.map((party) => (
              <a
                key={party.id}
                className="flex flex-row py-3 px-4 hover:bg-neutral"
                href={ emailLink(party.mps.map((mp) => mp.email)) }
              >
                <div className="flex w-full justify-between"> 
                  <div className="text-xl font-semibold pl-2 text-secondary">
                    {party.name}
                  </div>
                  <div className="text-white bg-primary rounded-full">
                    <FaRegEnvelope className="text-3xl p-1" />
                  </div>
                </div>
                
              </a>
            ))}
          </div>
        </section>
      </ContentWrapper>
    </>
  );
};

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate(-1)} className="absolute top-1 left-1 p-2">
      <FaCircleArrowLeft className="text-white text-4xl" />
    </button>
  );
};

const GuidelinesPage = () => {
  return (
    <ContentWrapper>
      <section className="p-4 my-2 bg-white">
        <h1 className="text-2xl font-bold text-secondary mb-4">
          These are the guidelines
        </h1>

        <h2 className="text-lg font-bold my-4 text-secondary">Email Tips</h2>
        <p>
          HAVE YOUR SAY is a free service provided by Family First NZ so that
          our elected representatives can hear the views of families on
          important issues. We would encourage the following:
        </p>

        <h3 className="text-lg font-bold my-4 text-secondary">
          Only Email involved MP's
        </h3>
        <p>
          Please only email MP’s that are directly involved in the issue. They
          receive hundreds of emails every day, so make sure your email is
          targeted at the right person/people.
        </p>

        <h3 className="text-lg font-bold my-4 text-secondary">
          Be brief and to the point
        </h3>
        <p>
          Please be brief, to the point, and always polite. Politicians are
          human just like us, and have limited time (just like us)! But it is
          important that they know what you think. Try to avoid simply ‘cutting’
          and ‘pasting’ what someone else has written. Say what you think! At
          all times, be polite. Politicians deserve our respect – even when we
          disagree with them.
        </p>

        <h3 className="text-lg font-bold my-4 text-secondary">
          Include contact details
        </h3>
        <p>
          Be sure to include your name and some form of contact details (address
          / phone). Locally elected MP’s will obviously be interested to know if
          you live in their electorate. Anonymous emails run the risk of being
          ignored.
        </p>
      </section>
    </ContentWrapper>
  );
};

const Layout = () => {
  const location = useLocation();

  return (
    <div className="flex flex-col min-h-screen">
      <header className="text-center py-12 border-t-fat border-secondary">
        {location.pathname !== "/" && <BackButton />}
        <img src="/images/logo_large.png" alt="Logo" className="mx-auto w-96" />
      </header>

      <Outlet className="flex-grow" />

      <footer className="text-center py-8 border-b-fat border-secondary">
        <div className="text-center max-w-[800px] mx-auto my-8">
          <p className="uppercase text-xs ">Sponsored by</p>
          <a href="https://familyfirst.org.nz/" target="_blank">
            <img
              src="/images/ff_logo_sml.jpg"
              alt="Family First NZ Logo"
              className="mx-auto w-60"
              title="Family First NZ"
            />
          </a>

          <div className="mt-12">
            <PrimaryButton url="https://familyfirst.org.nz/contact/">
              Contact Us
            </PrimaryButton>
          </div>
        </div>
      </footer>
    </div>
  );
};
const ContentWrapper = ({
  children,
  pageClass,
  containerClass = "bg-accent py-2",
}) => {
  return (
    <div className="flex-grow">
      <div className={pageClass}>
        <main
          className={"max-w-[1140px] mx-auto px-4 flex-grow " + containerClass}
        >
          {children}
        </main>
      </div>
    </div>
  );
};

const SearchBar = ({ value, onChange, label }) => {
  return (
    <div className="flex">
      <div className="flex-none align-middle pl-6 py-2 px-4 rounded-l-full text-white bg-secondary font-bold uppercase">
        Search
      </div>

      <input
        className="px-4 py-2 grow border-0 outline-none"
        type="text"
        onChange={onChange}
        placeholder={label}
      />
      <FaMagnifyingGlass className="flex-none text-slate-400 bg-white rounded-r-full py-2 text-4xl w-12 h-10  " />
    </div>
  );
};

const SelectMPsPage = () => {
  const { data } = useHysData(); // Assuming data is an array of MPs
  const [selectedMPs, setSelectedMPs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleMPSelected = (slug) => {
    setSelectedMPs((prevSelectedMPs) => {
      if (prevSelectedMPs.includes(slug)) {
        return prevSelectedMPs.filter((id) => id !== slug);
      } else {
        return [...prevSelectedMPs, slug];
      }
    });
  };

  const isMPSelected = (slug) => selectedMPs.includes(slug);

  const filteredMPs = data.people.filter((mp) => isMatch(mp, searchTerm));

  return (
    <>
      <section className="max-w-[1140px] mx-auto px-4 mb-4 pt-4 pb-8 px-20">
        <h1 className="text-3xl uppercase font-bold text-center py-2 text-secondary">
          Email selected MPs
        </h1>
        <p className="text-xl font-semi text-center">
        Search and select the specific MPs you wish to email. You can email multiple MPs at the same time. 
        </p>
      </section>
      <ContentWrapper>
        <section className="pt-8 pb-2 w-full">
          <SearchBar
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            label="Name or Party"
          />

          <div className="divide-y bg-white mt-8">
            {filteredMPs.map((mp) => (
              <CheckableItem
                key={mp.id}
                title={mp.name}
                subtitle={
                  mp.party + " " + (mp.electorate ? mp.electorate : "List MP")
                }
                checked={isMPSelected(mp.slug)}
                onChange={() => toggleMPSelected(mp.slug)}
              />
            ))}
          </div>
          <div className="mt-8 mb-4 flex flex-row-reverse">
            <div>
              <PrimaryButton
                onClick={() => {
                  const selectedEmails = data.people
                    .filter((mp) => selectedMPs.includes(mp.slug))
                    .map((mp) => mp.email);
                  emailPeople(selectedEmails);
                }}
              >
                Email MPs
              </PrimaryButton>
            </div>
          </div>
        </section>
      </ContentWrapper>
    </>
  );
};
const CheckableItem = ({
  title,
  subtitle,
  checked,
  onChange,
  miniSubtitle,
}) => {
  return (
    <div
      className="flex items-center py-3 px-4 hover:bg-neutral cursor-pointer"
      onClick={onChange}
    >
      <Checkbox checked={checked} />
      <div className="flex-grow pl-2">
        <div className="flex  ">
          <div className="text-xl font-semibold text-secondary pr-2">
            {title}
          </div>
          <div className="text-lg truncate">{subtitle}</div>
          <div className="text-xs text-ellipsis overflow-hidden whitespace-nowrap max-w-xs pt-2">
            {miniSubtitle}
          </div>
        </div>
      </div>
    </div>
  );
};

const Checkbox = ({ checked, onChange }) => {
  return (
    <span onClick={onChange}>
      {checked ? (
        <FaCircleCheck className="text-primary text-2xl" />
      ) : (
        <FaRegCircle className="text-primary text-2xl" />
      )}
    </span>
  );
};

const HysContext = createContext();

function nameToSlug(name) {
  return name
    .normalize("NFD") // Normalize the string to NFD Unicode form
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

function transformData(data) {
  let d = {}; // Placeholder for the transformation logic
  d.people = [];
  d.electorates = [];
  d.parties = [];
  for (var i in data.people) {
    if (data.people.hasOwnProperty(i)) {
      data.people[i].checked = false;
      
      if (
        data.people[i].electorate &&
        data.electorates[data.people[i].electorate]
      )
        data.people[i].electorate =
          data.electorates[data.people[i].electorate].name;
      if (data.people[i].list && data.electorates[data.people[i].list])
        data.people[i].list = data.electorates[data.people[i].list].name;
      if (data.people[i].party && data.parties[data.people[i].party]) {
        data.people[i].party_id = data.parties[data.people[i].party].id;
        data.people[i].party = data.parties[data.people[i].party].name;
      }
      data.people[i].slug = nameToSlug(data.people[i].name);
      d.people.push(data.people[i]);

    }
  }
  for (var j in data.parties) {
    if (data.parties.hasOwnProperty(j)) {
      d.parties.push( data.parties[j] );
    }
  }
  
  d.parties.sort((a, b) => a.name.localeCompare(b.name));
  d.electorates = data.electorates;
  // load in the more complex portfolio stuff
  const portfolios = Object.entries(data.portfolio).reduce(
    (acc, [key, value]) => {
      acc[value] = { name: value };
      return acc;
    },
    {}
  );

  Object.entries(data.people)
    .filter(([key, person]) => person.roles)
    .forEach(([key, person]) => {
      person.roles.forEach((role) => {
        if (role.role === "Minister" && portfolios[role.portfolio]) {
          portfolios[role.portfolio].mp = person;
        }
      });
    });

  const portfolioArray = Object.values(portfolios).filter(
    (portfolio) => portfolio.mp
  );

  d.portfolios = portfolioArray;

  return d;
}

export function HysProvider({ children }) {
  const initialState = transformData(mp_data);
  const [data] = useState(initialState);

  // Provide utility functions similar to `find`, `load`, etc.
  const findPersonBySlug = (slug) =>
    data.people.find((person) => person.slug === slug);
  const findPeopleByElectorate = (id) =>
    data.people.find((person) => person.electorate === id);
  const findPeopleByParty = (id) =>
    data.people.find((person) => person.party === id);

  const value = {
    data,
    findPersonBySlug,
    findPeopleByElectorate,
    findPeopleByParty,
  };

  return <HysContext.Provider value={value}>{children}</HysContext.Provider>;
}

// Define a hook for accessing the context data
export function useHysData() {
  const context = useContext(HysContext);
  if (context === undefined) {
    throw new Error("useHysData must be used within a HysProvider");
  }
  return context;
}

const NotFound = () => {
  return <h1>404 - Not Found</h1>;
};
const OfficeDetails = ({ office }) => {
  
  return (
    <div className="py-2">
      <h3 className="text-lg text-primary">{office.region}</h3>
      {office.phone && (
        <div>
          <strong>Phone</strong> <a href="tel:{office.phone}">{office.phone}</a>
        </div>
      )}
      {office.freephone && (
        <div>
          <strong>freephone</strong>{" "}
          <a href="tel:{office.freephone}">{office.freephone}</a>
        </div>
      )}
      {office.fax && (
        <div>
          <strong>fax</strong> {office.fax}
        </div>
      )}
      {office.email && (
        <div>
          <strong>email</strong>{" "}
          <a href="mailto:{office.email}">{office.email}</a>
        </div>
      )}
      {office.address && (
        <div>
          <strong>address</strong> <pre>{office.address}</pre>
        </div>
      )}
    </div>
  );
};

const MPPage = () => {
  const { name } = useParams(); // `name` matches the parameter name defined in the route

  // Use `name` to fetch data or perform other actions
  // For example, you might fetch information about the MP based on their name
  const { findPersonBySlug } = useHysData();
  const mp = findPersonBySlug(name);

  if (!mp) {
    return <NotFound />;
  }

  const roles =
    mp.roles &&
    mp.roles.map((role) => {
      return (
        <li>
          {role.role}
          {role.portfolio && <span> for {role.portfolio}</span>}
        </li>
      );
    });

  return (
    <ContentWrapper containerClass="">
      <div className="mx-auto relative flex flex-col text-gray-700 bg-white shadow-md bg-clip-border border-2 border-gray-100 rounded-xl w-2/3">
        {mp.hasImage && (
          <div className="relative mx-auto mt-8 overflow-hidden text-gray-700 bg-white shadow-lg bg-clip-border rounded-xl h-80 max-w-80">
            <img
              src={"/images/mp/" + mp.img}
              alt={"Photo of " + mp.name}
              className="object-cover w-full h-full rounded-xl object-top"
            />
          </div>
        )}

        <div className="p-6 text-center">
          <h4 className="block mb-2 text-3xl antialiased font-semibold leading-snug tracking-normal ">
            {mp.name}
          </h4>
          {mp.party && (
            <p className="block text-2xl font-medium leading-relaxed">
              {mp.party}
            </p>
          )}
          <p className="block text-xl leading-relaxed font-bold text-secondary">
            {mp.electorate || "List MP"}
          </p>

          {roles && (
            <div>
              <hr className="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700" />
              <ul className="py-4 ml-4 text-md">{roles}</ul>
            </div>
          )}
          <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700" />

          <div className="flex justify-center p-6 pt-2 gap-3">
  {mp.email && (<div className="pr-2">
                
                <a
                  href={'mailto:' + mp.email}
                  title={`Email ${mp.name} - ${mp.email}`}
                >
                  <FaRegEnvelope className="text-4xl" />
                </a>
              
            </div>)}
            {mp.phone && (<div className="pr-2">
              
              <a
                href={'tel:' + mp.phone}
                title={`Call ${mp.name} - ${mp.phone}`}
              >
                <FaPhone className="text-4xl" />
              </a>
            
          </div>)}
          {mp.twitter && (<div className="">
                
                <a href={mp.twitter} title={`Contact ${mp.name} on X`}>
                  <FaXTwitter className="text-4xl" />
                </a>
              
            </div>)}
            {mp.facebook && (<div className="pr-2">
                
                  <a
                    href={mp.facebook}
                    title={`Contact ${mp.name} on Facebook`}
                  >
                    <FaFacebook className="text-4xl" />
                  </a>
                
              </div>)}

  
  </div>

  <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700" />


          <div className="py-4">
            <h3 className="text-primary font-bold">Postal Address</h3>
            <div className="item item-text-wrap">
              {mp.name}
              <br />
              Freepost Parliament
              <br />
              Private Bag 18 888
              <br />
              Parliament Buildings
              <br />
              Wellington 6160
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
  );
};
function HysRouter() {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/select" element={<SelectMPsPage />} />
          <Route path="/electorate" element={<ElectorateMPsPage />} />
          <Route path="/mps" element={<MPListingPage />} />
          <Route path="/ministerial" element={<MinisterialMPsPage />} />
          <Route path="/mp/:name" element={<MPPage />} />
          <Route path="/guidelines" element={<GuidelinesPage />} />
          <Route path="/parties" element={<PartyMPsPage />} />
        </Route>
      </Routes>
    </Router>
  );
}
function HaveYourSay() {
  return (
    <HysProvider>
      <HysRouter />
    </HysProvider>
  );
}

export default HaveYourSay;
